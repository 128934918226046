<template>
  <v-row no-gutters>
    <v-row v-if="!xs" align="center" justify="start" class="pa-0 ma-0" >
      <v-col cols="12" sm="11" align="start" class="d-flex align-center pa-0 ma-0">
        <v-btn size="large" class="px-0 white--text" @click.stop="showWizard" append-icon="mdi-menu-down">
          <div>
            <v-list-item-title class="text-h6">
              <v-icon size="small" color="white"> mdi-map </v-icon>
              {{ title }}
            </v-list-item-title>
            <v-list-item-title class="text-subtitle-2">
              <v-icon color="white" class="mb-1"> mdi-map-marker </v-icon>
              {{ subtitle }}
            </v-list-item-title>
          </div>
        </v-btn>
        <CalendarSettingsDialog/>
      </v-col>
    </v-row>

    <v-row v-if="xs" align="center" justify="center" class="pa-0 ma-0" >
      <v-col cols="10" align="start" class="d-flex align-center pa-0 ma-0">
        <v-btn block variant="outlined" append-icon="mdi-menu-down" size="x-large" class="my-2 ml-1 mr-0 px-4" @click.stop="showWizard">
          <div>
            <v-list-item-title class="text-h6">
              <v-icon size="small" color="teal"> mdi-map </v-icon>
              {{ title }}
            </v-list-item-title>
            <v-list-item-title class="text-subtitle-2">
              <v-icon color="error" class="mb-1"> mdi-map-marker </v-icon>
              {{ subtitle }}
            </v-list-item-title>
          </div>
        </v-btn>
      </v-col>
        <CalendarSettingsDialog/>
    </v-row>

    <v-dialog v-model="wizard" width="500px" :fullscreen="$vuetify.display.xsOnly" persistent>
        <CalendarWizard @close="hideWizard"/>
    </v-dialog>
  </v-row>
</template>

<script setup>
import CalendarWizard from '@/components/CalendarWizard.vue'
import { useDisplay } from 'vuetify'
import CalendarSettingsDialog from '../components/CalendarSettingsDialog.vue'
import { ref, onBeforeMount } from 'vue'
import { useCalendarNameStore } from '@/stores/CalendarNameStore'
import { storeToRefs } from 'pinia'

const calendarNameStore = useCalendarNameStore()
const { xs } = useDisplay()

const { title, subtitle } = storeToRefs(calendarNameStore)
const wizard = ref(false)

const showWizard = () => {
  wizard.value = true
}

const hideWizard = () => {
  wizard.value = false
}

onBeforeMount(() => {
  calendarNameStore.loadName()
})

</script>
