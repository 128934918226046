<template>
  <div>
    <v-toolbar>
      <v-row align="center" justify="center" class="pa-0 ma-0">
        <v-col cols="2" class="text-center py-0 px-2 ma-0">
          <v-menu
            v-model="initialDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-bind="props"
                variant="outlined"
                hide-details
                density="compact"
                v-model="computedInitialDateFormatted"
                label="Data de início"
                prepend-inner-icon="mdi-calendar"
                readonly
              >
              </v-text-field>
            </template>

            <v-date-picker
              locale="ptBR"
              v-model="initialDate"
              hide-header
              @update:modelValue="initialDateMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="2" class="text-center py-0 px-2 ma-0">
          <v-menu
            v-model="finalDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-bind="props"
                variant="outlined"
                hide-details
                density="compact"
                v-model="computedFinalDateFormatted"
                label="Data de término"
                prepend-inner-icon="mdi-calendar"
                readonly
              >
              </v-text-field>
            </template>

            <v-date-picker
              locale="ptBR"
              v-model="finalDate"
              hide-header
              @update:modelValue="finalDateMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-row class="ma-0 pa-0" justify="center">
      <v-col v-for="item in newEvents" :key="item" cols=12 class="ma-0 pa-0">
        <v-timeline
          line-thickness="3"
          align="center"
          justify="center"
          :side="item.branch == 'animal' ? 'end' : 'start'"
          :class="item.branch == 'animal' ? 'pl-9' : 'pr-10'"
        >
          <v-timeline-item
            :dot-color="item.color"
            :icon="item.icon"
            fill-dot
          >
            <template v-slot:opposite>
              <strong> {{ item.date }} </strong>
              <div class="text-caption">
                Duração: {{ item.duration }}
                <span v-if="item.duration > 1"> dias </span>
                <span v-else> dia </span>
              </div>
            </template>
            <v-card density="compact" width="350px">
              <v-card-title class="text-wrap" :style="{'color': white, 'background-color': item.color}">
                {{ item.registers }}
              </v-card-title>
              <v-card-text class="mt-2">
                <strong> {{ item.category }} </strong>
                <p> {{ item.activity }} </p>
                <br/>
                <p> {{ item.description }} </p>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useDate } from 'vuetify'
import db from '@/db'

const initialDateMenu = ref(false)
const finalDateMenu = ref(false)
const initialDate = ref(null)
const finalDate = ref(null)

const newEvents = ref([])

onMounted(() => {
  getEvents()
})

watch(initialDate, async () => {
  getEvents()
})

watch(finalDate, async () => {
  getEvents()
})

const getEvents = async () => {
  let events = []

  await db.events
    .where('start')
    .between(FilterInitialDate(), FilterFinalDate(), true, true)
    .toArray()
    .then(result => {
      events = result
    })
    .catch(error => {
      console.error('Error gettinng events:', error)
    })

  const eventPromises = events.map(async event => {
    const eventRegistersLink = await db.eventRegisterLink.where('eCode').equals(event.code).toArray()

    const registersCodes = []
    eventRegistersLink.forEach(erl => {
      registersCodes.push(erl.rCode)
    })

    let registersNames = ''
    await db.registers
      .where('code')
      .anyOf(registersCodes)
      .toArray()
      .then(result => {
        result.forEach(reg => {
          if (registersNames === '') {
            registersNames = reg.name
          } else {
            registersNames = registersNames + ', ' + reg.name
          }
        })
      })
      .catch(error => {
        console.log('Error getting registers:', error)
      })

    const eStart = event.start.toLocaleDateString('pt-BR')
    const eCategory = await db.categories.where('code').equals(event.categoryCode).toArray()
    const eActivity = await db.activities.where('code').equals(event.activityCode).toArray()
    const eDuration = (event.end - event.start) / (24 * 60 * 60 * 1000)
    
    return {
      branch: eCategory.branch,
      category: eCategory[0].name,
      activity: eActivity[0].name,
      icon: event.icon,
      color: event.color,
      description: event.description,
      code: event.code,
      registers: registersNames,
      date: eStart,
      duration: eDuration
    }
  })

  newEvents.value = await Promise.all(eventPromises)
}

const formatDate = (date) => {
  const adapter = useDate()
  if (!date) return null
  return adapter.format(date, 'keyboardDate')
}

const computedInitialDateFormatted = computed(() => {
  if (initialDate.value === null) {
    return '01/01/2024'
  } else {
    return formatDate(initialDate.value)
  }
})

const computedFinalDateFormatted = computed(() => {
  if (finalDate.value === null) {
    return '31/12/2024'
  } else {
    return formatDate(finalDate.value)
  }
})

const FilterInitialDate = () => {
  if (initialDate.value === null) {
    const date = new Date(2024, 0, 1) //  01-Jan-2024
    return date
  } else {
    return initialDate.value
  }
}
const FilterFinalDate = () => {
  if (finalDate.value === null) {
    const date = new Date(2024, 11, 31) //  31-Dez-2024
    return date
  } else {
    return finalDate.value
  }
}

</script>
