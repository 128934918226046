<template>
  <div>
    <v-dialog
      v-model="loading"
      persistent
      max-width="600px"
    >
      <v-card class="pa-5">
        <v-card-title class="justify-center">
          <span class="text-p1 text-wrap">Aguarde, carregando os dados e sincronizando...</span>
        </v-card-title>
        <v-card-text>
          <v-progress-linear color="primary" indeterminate></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { defineModel } from 'vue'

const loading = defineModel()
</script>
