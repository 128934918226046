<template>
  <v-row class="ma-0 pa-0">
    <v-toolbar class="white--text" color="#009688" app fixed>
      <v-btn class="white--text" icon="mdi-arrow-left" @click="$router.push('/')"></v-btn>

      <v-toolbar-title>
        <CalendarTitle v-if="!xs"/>
      </v-toolbar-title>

      <v-btn
        color="warning"
        variant="flat"
        :prepend-icon="unsavedChanges ? 'mdi-database-alert-outline' : 'mdi-database-import-outline'"
        class="mr-3"
        @click="importexport = true"
      >
        Backup

        <template v-if="unsavedChanges" v-slot:prepend>
          <v-icon color="error" size="large"></v-icon>
        </template>
      </v-btn>
      
      <v-btn
      color="success"
      variant="flat"
      prepend-icon="mdi-sync"
      text="Sincronizar"
      class="mr-3"
      @click="emit('synch')"
      ></v-btn>
      
      <ImportExportDialog v-model="importexport" @backupDone="emit('backupDone')"/>
      <ActivitiesReportDialog/>
    </v-toolbar>
    <CalendarTitle v-if="xs" />

    <!--
    <CalendarBackupWarning v-model="backupWarning" :message="backupText" @backup="importexport = true" />
    -->
  </v-row>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { useDisplay } from 'vuetify'
import ActivitiesReportDialog from '../components/ActivitiesReportDialog.vue'
import CalendarTitle from '../components/CalendarTitle.vue'
import ImportExportDialog from '../components/ImportExportDialog.vue'
// import CalendarBackupWarning from '@/components/CalendarBackupWarning.vue'

const { xs } = useDisplay()

const props = defineProps(['backup'])
const emit = defineEmits(['synch', 'backupDone'])

const importexport = ref(false)

const lastBackup = ref(null)
const lastChange = ref(null)
const unsavedChanges = ref(props.backup)
const backupWarning = ref(false)
// const backupText = ref('Você tem alterações não salvas. Para garantir que seus dados não sejam perdidos, por favor, faça um backup antes de continuar.')

onMounted(() => {
  lastBackup.value = JSON.parse(localStorage.getItem('lastBackup'))
  lastChange.value = JSON.parse(localStorage.getItem('lastChange'))
  unsavedChanges.value = lastBackup.value < lastChange.value
  backupWarning.value = lastBackup.value < lastChange.value
})

watch(props, () => {
  unsavedChanges.value = props.backup
})

</script>
