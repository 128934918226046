<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script setup>
import { ref, watchEffect } from 'vue'

// Initialize ref values from localStorage, with fallback defaults
const email = ref(localStorage.getItem('email') || '')
const user = ref(JSON.parse(localStorage.getItem('user')) || {
  authenticated: false,
  name: '',
  email: '',
  picture: '',
  token: ''
})
const synchronized = ref(Number(localStorage.getItem('synchronized')) || 0)
const lastBackup = ref(Number(localStorage.getItem('lastBackup')) || 0)
const lastChange = ref(Number(localStorage.getItem('lastChange')) || 0)
const notShowTutorial = ref(localStorage.getItem('notShowTutorial') === 'true')
const beta = ref(localStorage.getItem('beta') === 'true')
const reliable = ref(localStorage.getItem('reliable') === 'true')

// Reactive updates using watchEffect
watchEffect(() => {
  localStorage.setItem('email', email.value)
  localStorage.setItem('user', JSON.stringify(user.value))
  localStorage.setItem('synchronized', synchronized.value)
  localStorage.setItem('lastBackup', lastBackup.value)
  localStorage.setItem('lastChange', lastChange.value)
  localStorage.setItem('notShowTutorial', notShowTutorial.value.toString())
  localStorage.setItem('beta', beta.value.toString())
  localStorage.setItem('reliable', reliable.value.toString())
})
</script>
