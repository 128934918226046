import { defineStore } from 'pinia'
import axios from 'axios'
import db from '@/db'

const api = import.meta.env.VITE_API

export const useCalendarCategoriesAndActivitiesStore = defineStore('calendarCategoriesAndActivities', {
  state: () => ({
    categoriesList: [],
    activitiesList: [],
    loaded: false
  }),
  actions: {
    async fetchData() {
      try {
        await axios.get(api + '/status', { timeout: 2000 });

        const categoryResponse = await axios.get(api + '/category/list');
        const categories = categoryResponse.data;

        for (const category of categories) {
          if (category.active) {
            // Save active category to Dexie
            await db.categories.put({
              code: category._id,
              active: category.active,
              author: category.author,
              name: category.name,
              icon: category.icon,
              branch: category.branch,
              subbranch: category.subbranch,
              created: category.created,
              changed: category.changed
            });

            try {
              const activityResponse = await axios.get(api + '/activity/' + category._id);
              const activities = activityResponse.data;

              if (activities.length === 0) {
                // Delete category if there are no activities for it
                await db.categories.delete(category._id);
                await db.activities.where('categoryCode').equals(category._id).delete();
              } else {
                for (const activity of activities) {
                  if (activity.active) {
                    // Save active activity to Dexie
                    await db.activities.put({
                      code: activity._id,
                      active: activity.active,
                      author: activity.author,
                      name: activity.name,
                      description: activity.description,
                      recommended: activity.recommended,
                      categoryCode: activity.category._id,
                      created: activity.created,
                      changed: activity.changed
                    });
                  } else {
                    // Delete inactive activity from Dexie
                    await db.activities.delete(activity._id);
                  }
                }
              }
            } catch (error) {
              console.error('Error fetching activities for category:', category._id, error);
            }
          } else {
            // Delete inactive category from Dexie
            await db.categories.delete(category._id);
          }
        }
        await this.loadData()
      } catch (error) {
        console.log('Error fetching categories and activities:', error);
      }
    },
    async updateData() {
      const lastSynch = JSON.parse(localStorage.getItem('synchronized')) || null;

      try {
        await axios.get(api + '/status', { timeout: 2000 });

        const categoryResponse = await axios.get(api + '/category/synch/list', { params: { lastSynch } });
        const categories = categoryResponse.data;

        for (const category of categories) {
          if (category.active) {
            // Save category to Dexie
            await db.categories.put({
              code: category._id,
              active: category.active,
              author: category.author,
              name: category.name,
              icon: category.icon,
              branch: category.branch,
              subbranch: category.subbranch,
              created: category.created,
              changed: category.changed
            });

            try {
              const activityResponse = await axios.get(api + '/activity/synch/' + category._id, { params: { lastSynch } });
              const activities = activityResponse.data;

              if (!activities || activities.length === 0) {
                // Delete category if there are no activities for it
                await db.categories.delete(category._id);
                // Re-enable this if you intend to delete related activities
                // await db.activities.where('categoryCode').equals(category._id).delete();
              } else {
                for (const activity of activities) {
                  if (activity.active) {
                    // Save activity to Dexie
                    await db.activities.put({
                      code: activity._id,
                      active: activity.active,
                      author: activity.author,
                      name: activity.name,
                      description: activity.description,
                      recommended: activity.recommended,
                      categoryCode: activity.category._id,
                      created: activity.created,
                      changed: activity.changed
                    });
                  } else {
                    // Delete inactive activity from Dexie
                    await db.activities.delete(activity._id);
                  }
                }
              }
            } catch (error) {
              console.error('Error fetching updated activities for category:', category._id, error);
            }
          } else {
            // Delete inactive category from Dexie
            await db.categories.delete(category._id);
          }
        }
        await this.loadData()
      } catch (error) {
        console.log('Error fetching updated categories:', error);
      }
    },
    async loadData() {
      try {
        const categories = await db.categories.toArray()

        if (categories.length === 0) {
          return
        }

        const activities = await db.activities.toArray()
      
        // Initialize the lists
        const activitiesListAux = []
        categories.forEach(cat => {
          const categoryActivities = activities.filter(activity => activity.categoryCode === cat.code)
          activitiesListAux.push(categoryActivities)
        })
        this.categoriesList = categories
        this.activitiesList = activitiesListAux
        this.loaded = true
      } catch (error) {
        console.error('Error loading activities and categories:', error);
      }
    }
  }
});
