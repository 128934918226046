<template>
    <v-snackbar
          v-model="snackbar"
          :timeout="snackbarTimeout"
        >
          {{ snackbarText }}

          <template v-slot:actions>
            <v-btn
              variant="text"
              @click="snackbar = false"
            >
              Fechar
            </v-btn>
          </template>
        </v-snackbar>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps(['text'])
const snackbar = defineModel()
const snackbarText = ref('')
const snackbarTimeout = ref(2000)

watch(props, () => {
  snackbarText.value = props.text
})

</script>
