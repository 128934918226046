import { createRouter, createWebHistory } from 'vue-router'

const options = [
  { path: '/about', component: 'AboutProject', name: 'Sobre', props: false },
  { path: '/', component: 'ViewCalendar', name: 'Calendário de Manejos', props: false },
  { path: '/:pathMatch(.*)*', component: 'NotFound', name: 'Página Não Encontrada', props: false }
]

const routes = options.map(route => {
  return {
    path: route.path,
    component: () => import(`@/views/${route.component}.vue`),
    name: route.name,
    props: route.props
  }
})

const router = createRouter({
  history: createWebHistory(),
  routes
})

const matomoConfig = {
  host: 'https://hit.embrapa.io',
  siteId: import.meta.env.VITE_MATOMO_ID,
  router,
  preInitActions: [
    ['setCustomDimension', 1, import.meta.env.VITE_STAGE],
    ['setCustomDimension', 2, import.meta.env.VITE_VERSION]
  ]
}

export { router, matomoConfig }
