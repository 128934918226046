<template>
  <div>
    <v-dialog
      v-model="eventEdit"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-toolbar density="compact" color="teal">
          <v-toolbar-title class="text-body">
            Editar Evento
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0">
            <v-container>
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-combobox
                    variant="outlined"
                    hide-details
                    chips
                    multiple
                    v-model="selectedRegisters"
                    :items="registers"
                    :label="eventBranch"
                    :rules="fieldRules"
                    required
                    class="mr-4"
                  >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toogle"
                    >
                      <v-list-item-action>
                        <v-icon :color="selectedRegisters.length > 0 ? 'indigo darken-4' : ''">
                          {{ checkboxIcon }}
                        </v-icon>
                        <v-list-item-title class="ma-2">
                          Todos
                        </v-list-item-title>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  </v-combobox>
                  <v-btn
                    variant="flat"
                    color="#009688"
                    icon="mdi-plus"
                    size="large"
                    @click="`${selectedBranch === 'vegetal' ? showNewPiquetDialog() : showNewBatchDialog()}`"
                  ></v-btn>
                  </v-col>
                  <v-col cols="12" sm="6">
                  <v-menu
                      v-model="newEventMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset="y"
                      max-width="290px"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ props }">
                    <v-text-field
                        v-bind="props"
                        variant="outlined"
                        hide-details
                        v-model="computedNewEventDateFormatted"
                        label="Data de início"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    locale="ptBR"
                    v-model="newEventDate"
                    no-title
                    @update:modelValue="newEventMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    required
                    v-model="duration"
                    variant="outlined"
                    hide-details
                    label="Duração em dias"
                    :rules="fieldRules"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="description"
                    variant="outlined"
                    hide-details
                    name="input-7-1"
                    :rules="fieldRules"
                    filled
                    label="Descrição"
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            prepend-icon="mdi-trash-can-outline"
            :append-icon="eventDelete ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            text="Apagar"
            @click="eventDelete = !eventDelete"
          >
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!eventDelete"
            color="teal"
            variant="text"
            prepend-icon="mdi-content-save-outline"
            @click="updateEvent()"
          >
          Salvar
          </v-btn>
          <v-btn
            v-if="!eventDelete"
            color="error"
            variant="text"
            @click="hideEventDialog()"
            prepend-icon="mdi-cancel"
          >
          Cancelar
          </v-btn>
        </v-card-actions>
        <v-card-text v-if="eventDelete">
          <v-row class="pa-0 ma-0">
            <v-col cols="8" class="pa-0 ma-0"> 
              Tem certeza que deseja apagar?
            </v-col>
            <v-col cols="4" class="pa-0 ma-0"> 
              <v-btn
                color="success"
                variant="flat"
                class="mr-4"
                @click="deleteEvent()"
              > 
                Sim 
              </v-btn>
              <v-btn
                color="error"
                variant="flat"
                @click="eventDelete = false"
              > 
                Não 
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <CalendarPiquetDialog v-model="newPiquetDialog" @refresh="refresh"/>
    <CalendarBatchDialog v-model="newBatchDialog" @refresh="refresh"/>
    <CalendarSnackbar v-model="eventSnackbar" :text="snackbarText"/>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useDate } from 'vuetify'
import { v4 as uuid } from 'uuid'
import db from '@/db'
import CalendarPiquetDialog from '../components/CalendarPiquetDialog.vue'
import CalendarBatchDialog from '../components/CalendarBatchDialog.vue'
import CalendarSnackbar from '../components/CalendarSnackbar.vue'

const eventEdit = defineModel()
const props = defineProps(['event', 'registers', 'branch'])
const emit = defineEmits(['refresh'])

const eventDelete = ref(false)
const event = ref(props.event)

const adapter = ref(useDate())

const registers = ref([])

const selectedBranch = ref(props.branch)
const selectedCategory = ref({})
const color = ref('')
const icon = ref('')
const selectedActivity = ref({})
const selectedRegisters = ref(props.registers)
const newEventDate = ref(null)
const duration = ref(0)
const description = ref('')

const newEventMenu = ref(false)

const newPiquetDialog = ref(false)
const newBatchDialog = ref(false)

const eventSnackbar = ref(false)
const snackbarText = ref('')

watch(props, async () => {
  selectedBranch.value = props.branch
  event.value = props.event
  color.value = props.event.color
  icon.value = props.event.icon
  selectedCategory.value = props.event.category
  selectedActivity.value = props.event.activity
  selectedRegisters.value = props.registers
  newEventDate.value = parseDateString(props.event.start)
  description.value = props.event.description
  await getRegistersNames()

  // Convert date strings to Date objects
  const startDate = parseDateString(props.event.start)
  const endDate = parseDateString(props.event.end)

  // Calculate the difference in milliseconds
  const durationInMillis = endDate - startDate

  // Convert the duration to days
  duration.value = (durationInMillis / (1000 * 60 * 60 * 24)) + 1
})

function parseDateString (dateString) {
  if (typeof dateString === 'string' || dateString instanceof String) {
    const [day, month, year] = dateString.split('/').map(Number);
    
    // Check if the values are valid
    if (!day || !month || !year || month < 1 || month > 12 || day < 1 || day > 31) {
      return new Date(NaN);
    }

    // JavaScript months are 0-based (0 = January, 1 = February, etc.)
    return new Date(year, month - 1, day);
  } else {
    return new Date(NaN);
  }
}

const fieldRules = [
  v => !!v || 'Campo obrigatório'
]

async function getRegistersNames () {
  registers.value = []
  await db.registers.each(register => {
    if (register.branch === selectedBranch.value) {
      registers.value.push(register.name)
    }
  })
}

const formatDate = (date) => {
  if (!date) return null
  return adapter.value.format(date, 'keyboardDate')
}

const toogle = () => {
  if (selectedAllItems.value || selectedSomeItems.value) {
    selectedRegisters.value = []
  } else if (selectedNoItems.value) {
    selectedRegisters.value = registers.value
  }
}

const validateInput = () => {
  if (selectedCategory.value &&
  selectedActivity.value &&
  selectedRegisters.value &&
  newEventDate.value &&
  duration.value &&
  description.value) {
    return true
  } else {
    console.log('Error in creating new event input: field invalid')
    return false
  }
}

const changeEvent = async () => {
  try {
    // Retrieve the existing event using its unique code
    const existingEvent = await db.events.where('code').equals(event.value.code).first()

    if (!existingEvent) {
      throw new Error('Event not found')
    }

    // Update the event with the new values
    const start = new Date(newEventDate.value)
    const end = new Date(start.getTime() + duration.value * 24 * 60 * 60 * 1000)

    existingEvent.categoryCode = selectedCategory.value.code
    existingEvent.activityCode = selectedActivity.value.code
    existingEvent.start = start
    existingEvent.end = end
    existingEvent.description = description.value
    existingEvent.color = color.value
    existingEvent.icon = icon.value
    existingEvent.changed = new Date()

    // Save the updated event back to the database
    await db.events.put(existingEvent)

    // Delete all existing register links for the event to avoid duplication
    await db.eventRegisterLink
      .where('eCode')
      .equals(existingEvent.code)
      .delete()

    // Process and add/update associated registers
    const registerCodes = await Promise.all(
      selectedRegisters.value.map(reg =>
        db.registers.where('name').equals(reg).first()
      )
    )

    for (const result of registerCodes) {
      if (result) {
        const erl = {
          code: uuid(),
          eCode: existingEvent.code,
          rCode: result.code
        }
        await db.eventRegisterLink.put(erl)
      }
    }

    // Reset form and show success message
    eventEdit.value = false
    newEventDate.value = null
    duration.value = null
    description.value = ''
    selectedRegisters.value = []
    snackbarText.value = 'Evento atualizado com sucesso'
    eventSnackbar.value = true
  } catch (error) {
    console.log('Error updating event or registers:', error)
  }
}

const deleteEvent = async () => {
  try {
    // Retrieve the event code or ID to delete
    const eventCode = event.value.code

    // Check if the event exists
    if (!eventCode) {
      throw new Error('Event code is not defined')
    }

    // Delete the specified event
    await db.events.delete(eventCode)

    // Reset form and show success message
    eventEdit.value = false
    newEventDate.value = null
    duration.value = null
    description.value = ''
    snackbarText.value = 'Evento apagado com sucesso'
    eventSnackbar.value = true
    
    // Process and remove associated registers if needed
    const registerCodes = await Promise.all(
      selectedRegisters.value.map(reg =>
        db.registers.where('name').equals(reg).first()
      )
    )
  
    for (const result of registerCodes) {
      if (result) {
      // Assuming you want to remove the links related to the deleted event
        await db.eventRegisterLink.where('eCode').equals(eventCode).delete()
      }
    }
  
    emit('refresh')
    eventDelete.value = false
    selectedRegisters.value = []
  } catch (error) {
    console.log('Error deleting event or associated registers:', error)
  }
}

const hideEventDialog = () => {
  eventEdit.value = false
  selectedRegisters.value = []
  emit('refresh')
}

const updateEvent = async () => {
  validateInput()
  await changeEvent()
  emit('refresh')
}

const showNewPiquetDialog = () => {
  newPiquetDialog.value = true
}

const showNewBatchDialog = () => {
  newBatchDialog.value = true
}

const computedNewEventDateFormatted = computed(() => formatDate(newEventDate.value))

const eventBranch = computed(() => {
  if (selectedBranch.value === 'vegetal') {
    return 'Piquete'
  } else {
    return 'Lote'
  }
})

const selectedAllItems = computed(() => {
  return selectedRegisters.value.length === registers.value.length
})

const selectedSomeItems = computed(() => {
  return selectedRegisters.value.length > 0 && !selectedAllItems.value
})

const selectedNoItems = computed(() => {
  return selectedRegisters.value.length === 0
})

const checkboxIcon = computed(() => {
  if (selectedAllItems.value) return 'mdi-close-box'
  if (selectedSomeItems.value) return 'mdi-minus-box'
  return 'mdi-checkbox-blank-outline'
})

const refresh = (newPiquetName, newBatchName) => {
  if (newPiquetName) selectedRegisters.value.push(newPiquetName)
  else if (newBatchName) selectedRegisters.value.push(newBatchName)
  emit('refresh')
}

</script>
