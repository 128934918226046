<template>
  <div>
    <v-dialog
      max-width="600px"
      v-model="importexport"
      persistent
    >
      <template v-slot:default="{ isActive }">
        <v-toolbar color="teal">
          <v-toolbar-title class="text-center mr-4">
            Backup
          </v-toolbar-title>
        </v-toolbar>

        <v-card>
          <v-tabs
            fixed-tabs
            v-model="activeTab"
          >
            <v-tab>
              <v-list-item-title>
                <v-icon> mdi-file-export-outline </v-icon>
                Exportar
              </v-list-item-title>
            </v-tab>
            <v-tab>
              <v-list-item-title>
                <v-icon> mdi-file-import-outline </v-icon>
                Importar
              </v-list-item-title>
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-text>
            <v-container
              style="height: 120px"
            >
              <v-row justify="center">
                <v-col v-if="activeTab == 0" align="center" cols="10">
                  <v-btn
                    color="warning"
                    variant="flat"
                    class="white--text mr-4"
                    size="large"
                    block
                    @click="backup()"
                  >
                    <v-icon> mdi-database-arrow-down-outline </v-icon>
                    <v-list-item-title class="ml-2"> Baixar backup </v-list-item-title>
                  </v-btn>
                </v-col>
                <v-col v-if="activeTab == 0" align="center" cols="10">
                  <v-btn
                    color="warning"
                    variant="flat"
                    class="white--text mr-4"
                    size="large"
                    block
                    @click="backup()"
                  >
                    <v-icon> mdi-file-excel-outline </v-icon>
                    <v-list-item-title class="ml-2"> Baixar planilha </v-list-item-title>
                  </v-btn>
                </v-col>
                <v-col v-if="activeTab == 1" align="center" cols="10">
                  <v-file-input
                    clearable
                    density="compact"
                    label="Importar backup"
                    variant="outlined"
                    class="mt-5"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              color="error"
              variant="text"
              prepend-icon="mdi-close-box-outline"
              @click="isActive.value = false"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script setup>
import { useDisplay } from 'vuetify'
import { ref, defineModel } from 'vue'

const { xs } = useDisplay()

const emit = defineEmits(['backupDone'])

const activeTab = ref(0)
const importexport = defineModel()

const backup = () => {
  localStorage.setItem('lastBackup', Date.now())
  importexport.value = false
  emit('backupDone')
}

</script>
