<template>
  <v-row justify="center">
    <v-dialog
      v-model="eventInfoDialog"
      persistent
      max-width="500px"
    >
      <v-card v-if="!eventEdit">
        <v-toolbar density="compact" :color="event.color">
          <v-icon class="ml-4 mr-0"> {{event.icon}} </v-icon>
          <v-toolbar-title class="text-body">
            {{ event.category.name }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col>
                <p class="mb-2 ml-9">
                  <strong class="text-h6"> {{ event.activity.name }} </strong>
                </p>
                <p class="mt-2 mb-4">
                  <v-icon class="ml-0 mr-3"> mdi-calendar-month </v-icon>
                  <span v-if="event.start === event.end"> {{ event.start }} </span>
                  <span v-else> {{ event.start }} ➡ {{ event.end }} </span>
                </p>
                <p v-for="index in eventRegisters.length" :key="index" class="mb-1">
                  <v-icon class="ml-0 mr-3 pb-2">
                    {{ branch === 'vegetal' ? 'mdi-fence' : 'mdi-cow' }}
                  </v-icon>

                  <span> {{ eventRegisters[index-1] }} </span>

                  <v-chip
                    v-if="branch === 'animal'"
                    size="small"
                    :color="
                      eventRegistersQualificator[index-1] == 'Sobreano' ? '#74a892' :
                      eventRegistersQualificator[index-1] == 'Touro' ? '#008585' :
                      eventRegistersQualificator[index-1] == 'Matriz' ? '#c7522a' :
                      eventRegistersQualificator[index-1] == 'Recém-nascidos' ? '#e5c185' :
                      eventRegistersQualificator[index-1] == 'Maternal' ? '#f0daa5' :
                      eventRegistersQualificator[index-1] == 'Desmama' ? '#fbf2c4' :
                      eventRegistersQualificator[index-1] == 'Pós-desmama' ? '#b8cdab' : 'black'
                    "
                    variant="flat"
                    class="ml-2"
                  >
                    {{ eventRegistersQualificator[index-1] }} - XX meses
                  </v-chip>
                </p>
                <p class="mt-3 mb-2">
                  <v-icon class="ml-0 mr-3"> mdi-text </v-icon>
                  <span> {{ event.description }} </span>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-icon size="x-small" color="grey" class="ml-2 pb-1"> mdi-clock-outline </v-icon>
          <span class="text-body-2 font-italic text-grey"> {{ event.changed }} </span>
          <v-spacer></v-spacer>
          <v-btn
            color="teal"
            variant="text"
            prepend-icon="mdi-pencil"
            @click="eventEdit = true"
          >
          Editar
          </v-btn>
          <v-btn
            color="error"
            variant="text"
            @click="hideEventInfo()"
            prepend-icon="mdi-close"
          >
          Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CalendarEventEdit v-model="eventEdit" :event="event" :registers="eventRegisters" :branch="branch" @refresh="hideEventInfo" />
    <CalendarSnackbar v-model="eventSnackbar" :text="snackbarText"/>
  </v-row>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useDate } from 'vuetify'
import CalendarSnackbar from './CalendarSnackbar.vue'
import db from '@/db'
import CalendarEventEdit from './CalendarEventEdit.vue';

const props = defineProps(['eCode'])
const emit = defineEmits(['refresh'])

const eventInfoDialog = defineModel()

const eventEdit = ref(false)

const event = ref({
  code: '',
  color: '',
  icon: '',
  category: {},
  activity: {},
  description: '',
  start: new Date(),
  end: new Date(),
  created: new Date(),
  changed: new Date()
})
const eventCode = ref(props.eCode)
const eventRegisters = ref([])
const eventRegistersQualificator = ref([])

const branch = ref('')

const eventSnackbar = ref(false)
const snackbarText = ref('')

const adapter = ref(useDate())

watch(props, async () => {
  if (props.eCode) {
    eventCode.value = props.eCode
    await getEventInfo()
  }
})

const hideEventInfo = () => {
  eventInfoDialog.value = false
  emit('refresh')
}

const getEventInfo = async () => {
  const eventRegistersCode = new Set()
  eventRegisters.value = []
  eventRegistersQualificator.value = []
  
  await db.events
    .where('code')
    .equals(eventCode.value)
    .toArray()
    .then(async result => {
      const category = await db.categories.where('code').equals(result[0].categoryCode).toArray()
      const activity = await db.activities.where('code').equals(result[0].activityCode).toArray()

      event.value.code = eventCode.value
      event.value.color = result[0].color
      event.value.icon = result[0].icon
      event.value.category = category[0]
      event.value.activity = activity[0]
      event.value.description = result[0].description
      event.value.start = formatDate(result[0].start)
      event.value.end = formatDate(new Date(result[0].end.getTime() - 60 * 60 * 1000))
      event.value.created = formatDate(result[0].created)
      event.value.changed = formatDate(result[0].changed)
    })
    .catch(error => {
      console.log('Error getting event info:', error)
    })

  await db.eventRegisterLink
    .where('eCode')
    .equals(eventCode.value)
    .toArray()
    .then(result => {
      result.forEach(erl => {
        eventRegistersCode.add(erl.rCode)
      })
    })
    .catch(error => {
      console.log('Error getting erl:', error)
    })

  await db.registers
    .where('code')
    .anyOf(eventRegistersCode)
    .toArray()
    .then(result => {
      result.forEach(reg => {
        eventRegisters.value.push(reg.name)
        eventRegistersQualificator.value.push(reg.qualificator)
        branch.value = reg.branch
      })
    })
    .catch(error => {
      console.log('Error getting registers:', error)
    })
}

const formatDate = (date) => {
  if (!date) return null
  return adapter.value.format(date, 'keyboardDate')
}

</script>
