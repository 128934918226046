import { defineStore } from 'pinia'
import db from '@/db'

export const useCalendarNameStore = defineStore('calendarName', {
  state: () => ({
    title: 'Calendário de Manejos',
    subtitle: ''
  }),
  actions: {
    async loadName() {
      db.lastAccessed.toArray()
        .then(lastAccessed => {
          if (lastAccessed.length && lastAccessed[0].farm) {
            const lastAccessedFarmCode = lastAccessed[0].farm
            return db.farm.where('code').equals(lastAccessedFarmCode).toArray()
          }
        })
        .then(farm => {
          if (farm && farm.length) {
            this.title = farm[0].name
            this.subtitle = farm[0].city + ' - ' + farm[0].state
          }
        })
        .catch(error => {
          console.error('Error in loading calendar name:', error)
        })
    },
    async changeName(farm) {
      this.title = farm.name
      this.subtitle = farm.city + ' - ' + farm.state
    }
  }
})