<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="snackbarTimeout"
  >
    {{ message }}
    <template v-slot:actions>
      <v-btn
        variant="text"
        @click="snackbar = false"
      >
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps(['text'])
const snackbar = defineModel()
const message = ref('')
const snackbarTimeout = ref(2000)

watch(props, () => {
  message.value = props.text
})

</script>
