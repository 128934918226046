import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import { VCalendar } from 'vuetify/labs/VCalendar'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { pt } from 'vuetify/locale'

const vuetify = createVuetify({
  components: {
    ...components,
    VCalendar
  },
  locale: {
    locale: 'pt',
    messages: { pt }
  },
  directives,
  date: {
    locale: 'pt-BR',
    firstDayOfWeek: 1
  }
})

export default vuetify
