import db from '@/db.js'
import { ref } from 'vue'

const unsavedChanges = ref(false)

const trackChanges = () => {
  db.tables.forEach(table => {
    table.hook('creating', () => {
      localStorage.setItem('lastChange', Date.now().toString())
      unsavedChanges.value = true
    })
    table.hook('updating', () => {
      localStorage.setItem('lastChange', Date.now().toString())
      unsavedChanges.value = true
    })
    table.hook('deleting', () => {
      localStorage.setItem('lastChange', Date.now().toString())
      unsavedChanges.value = true
    })
  })
}

const resetChanges = () => {
  unsavedChanges.value = false
  localStorage.setItem('lastBackup', Date.now().toString())
}

const useChangeTracker = () => {
  const lastBackup = Number(localStorage.getItem('lastBackup')) || 0
  const lastChange = Number(localStorage.getItem('lastChange')) || 0

  if (lastBackup < lastChange) {
    unsavedChanges.value = true
  }

  setTimeout(() => {
    trackChanges()
  }, 1000)

  return {
    unsavedChanges,
    resetChanges
  }
}

export default useChangeTracker;
