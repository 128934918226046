<template>
    <v-row justify="center">
      <v-dialog
        v-model="newPiquetDialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title class="justify-center">
            <span class="text-h5">Novo Piquete</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container>
              <v-row>
                <v-col cols="12" class="pa-0 ma-0">
                  <v-expand-transition>
                    <v-card flat>
                      <v-col cols="12">
                        <v-text-field
                          v-model="piquetName"
                          variant="outlined"
                          hide-details
                          label="Nome"
                          :rules="fieldRules"
                        ></v-text-field>
                      </v-col>
                    </v-card>
                  </v-expand-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              variant="text"
              @click="hideNewPiquetDialog()"
              prepend-icon="mdi-cancel"
            >
              <span>Cancelar</span>
            </v-btn>
            <v-btn
              color="#009688"
              variant="text"
              @click="addNewPiquet()"
              prepend-icon="mdi-file-document-plus-outline"
            >
              Registrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <CalendarSnackbar v-model="eventSnackbar" :text="snackbarText"/>
    </v-row>
</template>

<script setup>
import { ref, defineModel } from 'vue'
import { v4 as uuid } from 'uuid'
import db from '@/db'
import CalendarSnackbar from '../components/CalendarSnackbar.vue'

const emit = defineEmits(['refresh'])

const newPiquetDialog = defineModel()
const piquetName = ref('')

const eventSnackbar = ref(false)
const snackbarText = ref('')

const fieldRules = [
  v => !!v || 'Campo obrigatório'
]

const addNewPiquet = () => {
  const p = {
    code: uuid(),
    branch: 'vegetal',
    name: piquetName.value,
    visible: true,
    created: new Date(),
    changed: new Date()
  }
  db.registers.where('name').equals(p.name).first().then(existingPiquet => {
    if (existingPiquet) {
      newPiquetDialog.value = false
      piquetName.value = ''
      snackbarText.value = 'Piquete já existe!'
      console.log('Piquet already exists')
      eventSnackbar.value = true
    } else {
      db.registers.add(p).then(() => {
        console.log('Piquet registered successfully')
        emit('refresh', piquetName.value)
        newPiquetDialog.value = false
        piquetName.value = ''
        snackbarText.value = 'Piquete registrado com sucesso'
        eventSnackbar.value = true
      }).catch((error) => {
        console.log('Error adding piquet:', error)
      })
    }
  }).catch(error => {
    console.log(error)
  })
}

const hideNewPiquetDialog = () => {
  newPiquetDialog.value = false
}

</script>
