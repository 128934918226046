<template>
    <v-row justify="center">
      <v-dialog
        v-model="editPiquetDialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title class="justify-center">
            <span class="text-h5">Editar Piquete</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container>
              <v-row>
                <v-col cols="12" class="pa-0 ma-0">
                  <v-expand-transition>
                    <v-card flat>
                      <v-col cols="12">
                        <v-text-field
                          v-model="piquetName"
                          variant="outlined"
                          hide-details
                          label="Nome"
                          :rules="fieldRules"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-card>
                  </v-expand-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              variant="text"
              @click="hidePiquetDialog()"
            >
              <span>Cancelar</span>
            </v-btn>
            <v-btn
              color="#009688"
              variant="text"
              @click="editPiquet()"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="editBatchDialog"
        persistent
        max-width="600px"
    >
        <v-card>
        <v-card-title class="justify-center">
            <span class="text-h5">Editar Lote</span>
        </v-card-title>
        <v-card-text class="pa-0">
            <v-container>
            <v-row>
                <v-col cols="12"  class="pa-0 ma-0">
                <v-expand-transition>
                    <v-card flat>
                    <v-col cols="12">
                        <v-text-field
                        v-model="batchName"
                        variant="outlined"
                        hide-details
                        label="Nome"
                        :rules="fieldRules"
                        clearable
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-menu
                        v-model="editBatchMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ props }">
                            <v-text-field
                            v-bind="props"
                            variant="outlined"
                            hide-details
                            v-model="computededitBatchDateFormatted"
                            label="Data de Nascimento"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            locale="pt-BR"
                            v-model="editBatchDate"
                            no-title
                            @update:modelValue="editBatchMenu = false"
                        ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                        v-model="selectedQualificator"
                        variant="outlined"
                        hide-details
                        :items="selectedQualificators"
                        label="Qualificador"
                        ></v-select>
                    </v-col>
                    </v-card>
                </v-expand-transition>
                </v-col>
            </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="error"
          variant="text"
          @click="hideBatchDialog()"
          >
          <span>Cancelar</span>
          </v-btn>
          <v-btn
          color="#009688"
          variant="text"
          @click="editBatch()"
          >
          Confirmar
          </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>

      <CalendarSnackbar v-model="editSnackbar" :text="snackbarText"/>
    </v-row>
</template>

<script setup>
import CalendarSnackbar from '../components/CalendarSnackbar.vue'
import { ref, watch, onMounted, computed, reactive } from 'vue'
import { useDate } from 'vuetify'

const emit = defineEmits(['close'])

const props = defineProps(['selectedRegister'])
const model = defineModel()

const editPiquetDialog = ref(false)
const piquetName = ref('')

const editBatchDialog = ref(false)
const batchName = ref('')
const editBatchDate = ref(null)
const editBatchMenu = ref(false)
const selectedQualificator = ref('')
const selectedQualificators = ref([])

const editSnackbar = ref(false)
const snackbarText = ref('Editado com sucesso')

const register = ref(null)

watch(props, () => {
  register.value = registersExemple.filter(register => register.name === props.selectedRegister)
  register.value = register.value[0]

  if (props.selectedRegister.includes('piquete')) {
    editPiquetDialog.value = model.value
    piquetName.value = register.value.name
  } else if (props.selectedRegister.includes('lote')) {
    editBatchDialog.value = model.value
    batchName.value = register.value.name
    editBatchDate.value = register.value.dateOfBirth
    selectedQualificator.value = register.value.qualificator
  }
})

watch(editBatchDate, () => {
  batchQualificator()
})

const fieldRules = [
  v => !!v || 'Campo obrigatório'
]

const editPiquet = () => {
  editSnackbar.value = true
  console.log('Piquet edited successfully')
  emit('close')
}

const hidePiquetDialog = () => {
  piquetName.value = ''
  emit('close')
}

const formatDate = (date) => {
  const adapter = useDate()
  if (!date) return null
  return adapter.format(date, 'keyboardDate')
}

const editBatch = () => {
  editSnackbar.value = true
  console.log('Batch edited successfully')
  emit('close')
}

const hideBatchDialog = () => {
  batchName.value = ''
  emit('close')
}

const batchQualificator = () => {
  const batchDate = new Date(editBatchDate.value)
  const currentDate = new Date()

  const batchAge = (currentDate.getFullYear() - batchDate.getFullYear()) * 12 +
        (currentDate.getMonth() - batchDate.getMonth())

  if (batchAge > 12) {
    selectedQualificators.value = qualificators.slice(0, 3)
  } else if (batchAge > 9 && batchAge <= 12) {
    selectedQualificators.value = qualificators.slice(6, 7)
  } else if (batchAge > 6 && batchAge <= 9) {
    selectedQualificators.value = qualificators.slice(5, 6)
  } else if (batchAge > 3 && batchAge <= 6) {
    selectedQualificators.value = qualificators.slice(4, 5)
  } else {
    selectedQualificators.value = qualificators.slice(3, 4)
  }
}

const computededitBatchDateFormatted = computed(() => formatDate(editBatchDate.value))

onMounted(() => {
  const adapter = useDate()
  const currentDate = adapter.value
  editBatchDate.value = currentDate
})

const qualificators = ['Sobreano', 'Touro', 'Matriz', 'Recém-nascidos', 'Maternal', 'Desmama', 'Pós-desmama']

const registersExemple = reactive([
  {
    name: 'piquete1',
    title: 'Rótulo piquete1',
    description: 'Descrição piquete1',
    visibility: true
  },
  {
    name: 'piquete2',
    title: 'Rótulo piquete2',
    description: 'Descrição piquete2',
    visibility: true
  },
  {
    name: 'piquete3',
    title: 'Rótulo piquete3',
    description: 'Descrição piquete3',
    visibility: true
  },
  {
    name: 'lote1',
    dateOfBirth: 'Data de nasciento lote1',
    qualificator: 'Qualificaor lote1',
    description: 'Descrição lote1',
    visibility: true
  },
  {
    name: 'lote2',
    dateOfBirth: 'Data de nasciento lote2',
    qualificator: 'Qualificaor lote2',
    description: 'Descrição lote2',
    visibility: true
  },
  {
    name: 'lote3',
    dateOfBirth: 'Data de nasciento lote3',
    qualificator: 'Qualificaor lote3',
    description: 'Descrição lote3',
    visibility: true
  }
])

</script>
