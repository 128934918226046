<template>
    <v-row cols="auto">
            <v-dialog
              v-model="registerDetailDialog"
              transition="dialog-bottom-transition"
              max-width="600"
            >
                <v-card>
                  <v-card-title>
                  <span class="text-h4 text--primary">{{ selectedRegister }}</span>
                  </v-card-title>
                  <v-card-text v-if="selectedRegister.includes('piquete')">
                    <div class="text--primary">
                      <p>{{ getPiquetInfo().title }}</p>
                      <p>{{ getPiquetInfo().description }}</p>
                    </div>
                  </v-card-text>
                  <v-card-text v-if="selectedRegister.includes('lote')">
                    <div class="text--primary">
                      <p>{{ getBatchInfo().dateOfBirth }}</p>
                      <p>{{ getBatchInfo().qualificator }}</p>
                      <p>{{ getBatchInfo().description }}</p>
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                    variant="text"
                      @click="registerDetailDialog = false"
                    >Fechar</v-btn>
                  </v-card-actions>
                </v-card>
            </v-dialog>
          </v-row>
</template>

<script setup>
import { ref, watch, reactive } from 'vue'

const props = defineProps(['register'])
const selectedRegister = ref('')
const registerDetailDialog = defineModel()

const getBatchInfo = () => {
  let info = {}
  registersExemple.forEach(register => {
    if (register.name === selectedRegister.value) {
      info = register
    }
  })
  return info
}

const getPiquetInfo = () => {
  let info = {}
  registersExemple.forEach(register => {
    if (register.name === selectedRegister.value) {
      info = register
    }
  })
  return info
}

watch(props, () => {
  selectedRegister.value = props.register
})

const registersExemple = reactive([
  {
    name: 'piquete1',
    title: 'Rótulo piquete1',
    description: 'Descrição piquete1',
    visibility: true
  },
  {
    name: 'piquete2',
    title: 'Rótulo piquete2',
    description: 'Descrição piquete2',
    visibility: true
  },
  {
    name: 'piquete3',
    title: 'Rótulo piquete3',
    description: 'Descrição piquete3',
    visibility: true
  },
  {
    name: 'lote1',
    dateOfBirth: 'Data de nasciento lote1',
    qualificator: 'Qualificaor lote1',
    description: 'Descrição lote1',
    visibility: true
  },
  {
    name: 'lote2',
    dateOfBirth: 'Data de nasciento lote2',
    qualificator: 'Qualificaor lote2',
    description: 'Descrição lote2',
    visibility: true
  },
  {
    name: 'lote3',
    dateOfBirth: 'Data de nasciento lote3',
    qualificator: 'Qualificaor lote3',
    description: 'Descrição lote3',
    visibility: true
  }
])

</script>
