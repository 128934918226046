<template>
  <div>
    <v-dialog
      v-model="error"
      persistent
      max-width="600px"
    >
      <v-card class="pa-5">
        <v-card-title class="justify-center">
          <v-icon color="error" class="mr-3">mdi-alert-circle-outline</v-icon>
          <span class="text-p1 text-wrap">Sem conexão com a internet</span>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              color="warning"
              class="white--text"
              @click="error = false"
            >Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

const error = defineModel()
const props = defineProps(['text'])

const message = ref('Erro inesperado!')

watch(props, () => {
  message.value = props.text
})

</script>
