import { defineStore } from 'pinia'
import db from '@/db'
import { useCalendarDateStore } from './CalendarDateStore'
import { storeToRefs } from 'pinia'

const calendarDateStore = useCalendarDateStore()
const { calendarYear, calendarMonth } = storeToRefs(calendarDateStore)

export const useCalendarEventsStore = defineStore('calendarEvents', {
  state: () => ({
    events: []
  }),
  actions: {
    async getEvents () {
      let events = []
      const newEvents = []
    
      const startOfMonth = new Date(calendarYear.value, calendarMonth.value - 1, 1)
      const endOfMonth = new Date(calendarYear.value, calendarMonth.value, 0)
    
      await db.events
        .filter(event => {
          return (
            (event.start >= startOfMonth && event.start <= endOfMonth) || // Event starts in the month
            (event.end >= startOfMonth && event.end <= endOfMonth) || // Event ends in the month
            (event.start <= startOfMonth && event.end >= endOfMonth) // Event spans the entire month
          );
        })
        .toArray()
        .then(result => {
          events = result
        })
        .catch(error => {
          console.error('Error gettinng events:', error)
        })
    
      await Promise.all(events.map(async event => {
        let eventDisplay = 'none'
    
        const eventRegistersLink = await db.eventRegisterLink.where('eCode').equals(event.code).toArray()
    
        const registers = []
        eventRegistersLink.forEach(erl => {
          registers.push(erl.rCode)
        })
    
        let allRegistersNotVisible = true
    
        await Promise.all(registers.map(async registerCode => {
          const register = await db.registers.get(registerCode)
          if (register && register.visible) {
            allRegistersNotVisible = false
          }
        }))
    
        if (!allRegistersNotVisible) {
          eventDisplay = 'block'
        }
    
        const activity = await db.activities.where('code').equals(event.activityCode).toArray()
        const e = {
          id: event.code,
          start: event.start,
          end: event.end,
          title: activity[0].name,
          extendedProps: {
            description: event.description,
            created: event.created,
            changed: event.changed
          },
          color: event.color,
          display: eventDisplay,
          allDay: true
        }
    
        newEvents.push(e)
      }))
      this.events = newEvents
    }
  }
})