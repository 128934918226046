<template>
  <v-row align="start" no-gutters>
    <v-col cols="12" align-center>
      <v-dialog v-model="introCalendarForm" width="1000px" persistent>
        <v-card>
          <v-card-text>
            <p>texto exemplo</p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Ut exercitationem ratione suscipit ex repellendus voluptatum pariatur qui, maiores,
              quam numquam deleniti nisi voluptatem natus incidunt fugit doloribus quaerat, porro minima?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Ut exercitationem ratione suscipit ex repellendus voluptatum pariatur qui, maiores,
              quam numquam deleniti nisi voluptatem natus incidunt fugit doloribus quaerat, porro minima?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Ut exercitationem ratione suscipit ex repellendus voluptatum pariatur qui, maiores,
              quam numquam deleniti nisi voluptatem natus incidunt fugit doloribus quaerat, porro minima?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#009688"
              class="white--text"
              prepend-icon="mdi-check-underline"
              @click="showWizard"
            >Entendi</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-dialog v-model="wizard" width="500px" :fullscreen="$vuetify.display.xsOnly" persistent>
      <CalendarWizard @close="hideWizard"/>
    </v-dialog>
  </v-row>
</template>

<script setup>
import CalendarWizard from '@/components/CalendarWizard.vue'
import { ref } from 'vue'
import db from '@/db'

const introCalendarForm = ref(true)
const wizard = ref(false)

const showWizard = () => {
  introCalendarForm.value = false

  db.lastAccessed.toArray()
    .then(lastAccessed => {
      if (!lastAccessed.length || !lastAccessed[0].farm) {
        wizard.value = true
      }
    })
    .catch(error => {
      console.error('Error in beforeMount:', error)
    })
}

const hideWizard = () => {
  wizard.value = false
}
</script>
