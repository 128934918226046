<template>
  <div>
    <v-dialog
      v-model="reportDialog"
      persistent
      scrollable
      transition="dialog-bottom-transition"
      fullscreen
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          color="red"
          variant="flat"
          class="white--text mr-4"
          @click="reportDialog = true"
        >
          <v-icon> mdi-file-document </v-icon>
          <v-list-item-title class="ml-2"> Relatório </v-list-item-title>
        </v-btn>
      </template>
      <template v-slot:default="{ isActive }">
        <v-toolbar color="#009688">
          <v-btn class="white--text" icon="mdi-arrow-left" @click="isActive.value = false"></v-btn>
          <v-toolbar-title class="text-start">
            Relatório de atividades
          </v-toolbar-title>
          <v-btn
            color="red"
            size="large"
            variant="flat"
            class="white--text mr-4"
          >
            <v-icon v-if="xs" size="large"> mdi-file-pdf-box </v-icon>
            <v-icon v-else> mdi-file-document </v-icon>

            <v-list-item-title v-if="!xs" class="ml-2"> PDF </v-list-item-title>
          </v-btn>
        </v-toolbar>

        <v-card>
          <ActivitiesTimeLine :initialDate="initialDate" :finalDate="finalDate"/>

          <v-card-actions></v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script setup>
import { useDisplay, useDate } from 'vuetify'
import { ref, computed } from 'vue'
import ActivitiesTimeLine from '../components/ActivitiesTimeLine.vue'

const { xs } = useDisplay()

const initialDateMenu = ref(false)
const finalDateMenu = ref(false)
const initialDate = ref(null)
const finalDate = ref(null)

const reportDialog = ref(false)

const formatDate = (date) => {
  const adapter = useDate()
  if (!date) return null
  return adapter.format(date, 'keyboardDate')
}

const computedInitialDateFormatted = computed(() => formatDate(initialDate.value))
const computedFinalDateFormatted = computed(() => formatDate(finalDate.value))

</script>
