<template>
  <v-row justify="center">
      <v-dialog
        v-model="newEventDialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title class="justify-center">
            <span class="text-p1 text-wrap">{{ 'Novo evento: ' + selectedCategory.name + ' / ' + selectedActivity.name }}</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container>
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-combobox
                    variant="outlined"
                    hide-details
                    chips
                    multiple
                    v-model="selectedRegisters"
                    :items="registers"
                    :label="eventBranch"
                    :rules="fieldRules"
                    required
                    class="mr-4"
                  >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toogle"
                    >
                      <v-list-item-action>
                        <v-icon :color="selectedRegisters.length > 0 ? 'indigo darken-4' : ''">
                          {{ checkboxIcon }}
                        </v-icon>
                        <v-list-item-title class="ma-2">
                          Todos
                        </v-list-item-title>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  </v-combobox>
                  <v-btn
                    variant="flat"
                    color="#009688"
                    icon="mdi-plus"
                    size="large"
                    @click="`${selectedBranch === 'vegetal' ? showNewPiquetDialog() : showNewBatchDialog()}`"
                  ></v-btn>
                  </v-col>
                  <v-col cols="12" sm="6">
                  <v-menu
                      v-model="newEventMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset="y"
                      max-width="290px"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ props }">
                    <v-text-field
                        v-bind="props"
                        variant="outlined"
                        hide-details
                        v-model="computedNewEventDateFormatted"
                        label="Data de início"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    locale="ptBR"
                    v-model="newEventDate"
                    no-title
                    @update:modelValue="newEventMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    required
                    v-model="duration"
                    variant="outlined"
                    hide-details
                    label="Duração em dias"
                    :rules="fieldRules"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="description"
                    variant="outlined"
                    hide-details
                    name="input-7-1"
                    :rules="fieldRules"
                    filled
                    label="Descrição"
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
              <!-- DISCLAIMER -->
              <v-row>
                <p class="ml-3 font-weight-bold text-warning">Atenção: Este evento é referente a este mês.</p>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              variant="text"
              @click="hideEventDialog()"
              prepend-icon="mdi-cancel"
            >
            Cancelar
            </v-btn>
            <v-btn
              color="teal"
              variant="text"
              @click="createEvent()"
              prepend-icon="mdi-plus"
            >
            Criar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <CalendarPiquetDialog v-model="newPiquetDialog" @refresh="refresh"/>
      <CalendarBatchDialog v-model="newBatchDialog" @refresh="refresh"/>
      <CalendarSnackbar v-model="eventSnackbar" :text="snackbarText"/>
  </v-row>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { useDate } from 'vuetify'
import { v4 as uuid } from 'uuid'
import db from '@/db'
import CalendarPiquetDialog from '../components/CalendarPiquetDialog.vue'
import CalendarBatchDialog from '../components/CalendarBatchDialog.vue'
import CalendarSnackbar from '../components/CalendarSnackbar.vue'
import { useCalendarEventsStore } from '@/stores/CalendarEventsStore'
import { storeToRefs } from 'pinia'

const props = defineProps(['category', 'categoryColor', 'categoryIcon', 'branch', 'activity'])
const emit = defineEmits(['refresh'])

const calendarEventsStore = useCalendarEventsStore()
const { events, newEvent } = storeToRefs(calendarEventsStore)

const registers = ref([])

const selectedBranch = ref(props.branch)
const selectedCategory = ref(props.category)
const color = ref(props.categoryColor)
const icon = ref(props.categoryIcon)
const selectedActivity = ref(props.activity)
const selectedRegisters = ref([])
const newEventDate = ref(null)
const duration = ref(null)
const description = ref('')

const newEventDialog = defineModel()
const newEventMenu = ref(false)

const newPiquetDialog = ref(false)
const newBatchDialog = ref(false)

const eventSnackbar = ref(false)
const snackbarText = ref('')

const fieldRules = [
  v => !!v || 'Campo obrigatório'
]

const formatDate = (date) => {
  const adapter = useDate()
  if (!date) return null
  return adapter.format(date, 'keyboardDate')
}

const toogle = () => {
  if (selectedAllItems.value || selectedSomeItems.value) {
    selectedRegisters.value = []
  } else if (selectedNoItems.value) {
    selectedRegisters.value = registers.value
  }
}

const validateInput = () => {
  if (selectedCategory.value &&
  selectedActivity.value &&
  selectedRegisters.value &&
  newEventDate.value &&
  duration.value &&
  description.value) {
    return true
  } else {
    console.log('Error in creating new event input: field invalid')
    return false
  }
}

const addNewEvent = async () => {
  try {
    const start = new Date(newEventDate.value)
    const end = new Date(start.getTime() + duration.value * 24 * 60 * 60 * 1000)

    const e = {
      code: uuid(),
      categoryCode: selectedCategory.value.code,
      activityCode: selectedActivity.value.code,
      start: start,
      end: end,
      description: description.value,
      color: color.value,
      icon: icon.value,
      created: new Date(),
      changed: new Date()
    }

    await db.events.add(e)

    newEventDialog.value = false
    newEventDate.value = null
    duration.value = null
    description.value = ''
    snackbarText.value = 'Evento criado com sucesso'
    eventSnackbar.value = true

    const registerCodes = await Promise.all(
      selectedRegisters.value.map(reg =>
        db.registers.where('name').equals(reg).first()
      )
    )

    for (const result of registerCodes) {
      if (result) {
        const erl = {
          code: uuid(),
          eCode: e.code,
          rCode: result.code
        }
        await db.eventRegisterLink.put(erl)
      }
    }

    selectedRegisters.value = []
  } catch (error) {
    console.log('Error adding event or registers:', error)
  }
}

const hideEventDialog = () => {
  newEventDialog.value = false
  selectedRegisters.value = []
  calendarEventsStore.getEvents()
  emit('refresh')
}

const createEvent = async () => {
  validateInput()
  await addNewEvent()
  calendarEventsStore.getEvents()
  emit('refresh')
}

async function getRegistersNames () {
  registers.value = []
  await db.registers.each(register => {
    if (register.branch === selectedBranch.value) {
      registers.value.push(register.name)
    }
  })
}

const showNewPiquetDialog = () => {
  newPiquetDialog.value = true
}

const showNewBatchDialog = () => {
  newBatchDialog.value = true
}

watch(props, () => {
  selectedCategory.value = props.category
  color.value = props.categoryColor
  icon.value = props.categoryIcon
  selectedBranch.value = props.branch
  selectedActivity.value = props.activity
  getRegistersNames()
})

const computedNewEventDateFormatted = computed(() => formatDate(newEventDate.value))

const eventBranch = computed(() => {
  if (selectedBranch.value === 'vegetal') {
    return 'Piquete'
  } else if (selectedBranch.value === 'animal') {
    return 'Lote'
  } else {
    return console.log('Invalid selected branch value')
  }
})

const selectedAllItems = computed(() => {
  return selectedRegisters.value.length === registers.value.length
})

const selectedSomeItems = computed(() => {
  return selectedRegisters.value.length > 0 && !selectedAllItems.value
})

const selectedNoItems = computed(() => {
  return selectedRegisters.value.length === 0
})

const checkboxIcon = computed(() => {
  if (selectedAllItems.value) return 'mdi-close-box'
  if (selectedSomeItems.value) return 'mdi-minus-box'
  return 'mdi-checkbox-blank-outline'
})

const refresh = (newPiquetName, newBatchName) => {
  if (newPiquetName) selectedRegisters.value.push(newPiquetName)
  else if (newBatchName) selectedRegisters.value.push(newBatchName)
  getRegistersNames()
  calendarEventsStore.getEvents()
  emit('refresh')
}

onMounted(() => {
  const adapter = useDate()
  const currentDate = adapter.value
  newEventDate.value = currentDate
})

</script>
