<template>
  <div>
    <v-dialog
      persistent
      max-width="600px"
      :transition="transition"
      :fullscreen="xs"
    >
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text" class="px-0">
          <v-icon size="x-large" class="mt-2"> mdi-tune </v-icon>
        </v-btn>
      </template>
      <template v-slot:default="{ isActive }">
        <v-card style="height: 500px">
          <v-toolbar color="#009688">
            <v-toolbar-title class="text-center"> Ajustes </v-toolbar-title>
          </v-toolbar>

          <v-tabs
            fixed-tabs
            v-model="activeTab"
          >
            <v-tab>
              <v-list-item-title>Piquetes</v-list-item-title>
            </v-tab>
            <v-tab>
              <v-list-item-title>Lotes</v-list-item-title>
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>

          <v-card-text>
            <v-container
              class="overflow-y-auto"
              style="height: 300px"
            >
              <p v-if="availableRegisters.length === 0">
                {{ "Nenhum " + (activeTab === 0 ? "piquete" : "lote") + " encontrado..." }}
              </p>
              <v-list>
                <v-list-item
                  v-for="(register, index) in availableRegisters"
                  :key="index"
                  align="center"
                  justify="center"
                >
                  <v-row no-gutters align="center">
                    <v-col>
                      <v-sheet class="ma-2 pa-2">
                        <p :class="getRegisterVisibility(register.name) ? 'text-high-emphasis' : 'text-disabled'">
                          {{ register.name }}
                        </p>
                      </v-sheet>
                    </v-col>
                    <v-col>
                      <v-btn-toggle
                        v-model="toggleSettingOption"
                        variant="outlined"
                        divided
                      >
                        <v-btn icon="mdi-pencil-outline" @click="openEditDialog(register.name)"></v-btn>
                        <v-btn :icon="getRegisterVisibility(register.name) ? 'mdi-eye-outline' : 'mdi-eye-closed'" @click="toggleVisibility(register.name)"></v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-container>
          </v-card-text>

          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              variant="text"
              prepend-icon="mdi-close-box-outline"
              @click="isActive.value = false"
            >
              Fechar
            </v-btn>
          </template>
        </v-card>
        <CalendarEditDialog v-model="editDialog" :selectedRegister="selectedRegisterName" @close="closeEditDialog()"/>
      </template>
    </v-dialog>
    <CalendarSnackbar v-model="settingsSnackbar" :text="snackbarText"/>
    <CalendarSnackbar v-model="visibilitySnackbar" :text="snackbarText"/>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useDisplay } from 'vuetify'
import CalendarEditDialog from './CalendarEditDialog.vue'
import CalendarSnackbar from './CalendarSnackbar.vue'
import db from '@/db'

const editDialog = ref(false)
const activeTab = ref(0)
const toggleSettingOption = ref(null)
const selectedRegisterName = ref('')

const { xs } = useDisplay()

const settingsSnackbar = ref(false)
const visibilitySnackbar = ref(false)
const snackbarText = ref('')

const availableRegisters = ref([])

const transition = computed(() => {
  if (xs.value) {
    return 'dialog-bottom-transition'
  }
  return undefined
})

const getAvailableRegisters = async () => {
  if (activeTab.value === 0) {
    await db.registers.where('branch').equals('vegetal').toArray().then(result => {
      availableRegisters.value = result
    })
  } else {
    await db.registers.where('branch').equals('animal').toArray().then(result => {
      availableRegisters.value = result
    })
  }
}

const toggleVisibility = async (selectedRegister) => {
  await db.registers.where('name').equals(selectedRegister).modify(reg => {
    reg.visible = !reg.visible
    availableRegisters.value.find(register => register.name === selectedRegister).visible = reg.visible
    if (reg.visible) {
      snackbarText.value = selectedRegister + ' ativado'
    } else {
      snackbarText.value = selectedRegister + ' desativado'
    }
    visibilitySnackbar.value = true
  })
}

const getRegisterVisibility = (selectedRegister) => {
  const register = availableRegisters.value.find(register => register.name === selectedRegister)
  return register.visible
}

const openEditDialog = (selectedRegister) => {
  editDialog.value = true
  selectedRegisterName.value = selectedRegister
}

const closeEditDialog = () => {
  editDialog.value = false
}

onMounted(() => {
  getAvailableRegisters()
})

watch(activeTab, () => {
  getAvailableRegisters()
})

</script>
