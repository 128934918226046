import Dexie from 'dexie'

const db = new Dexie('calendario-de-manejos')

db.version(30).stores({
  categories: 'code, active, author, name, icon, branch, subbranch, created, changed',
  activities: 'code, active, author, name, description, recommended, categoryCode, created, changed',
  farm: 'code, name, city, state, created, changed',
  lastAccessed: 'code, farm',
  events: 'code, categoryCode, activityCode, start, end, description, color, done, created, changed',
  eventRegisterLink: 'code, eCode, rCode',
  registers: 'code, branch, name, dateOfBirth, qualificator, visible, created, changed'
})

export default db
